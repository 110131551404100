import React, { useState } from 'react';
import './App.css';
import Main from './components/Main.jsx';


function App() {
  

  return (
    <div className="App" style={{ height: '100%' }}>
        <Main /> 
    </div>
  );
}

export default App;
